<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="m-auto">
      <v-card-title style="background-color: transparent !important">
        <h3>تغییر اطلاعات شیفت</h3>
      </v-card-title>
      <v-card class="cards">
        <v-card-text>
          <v-container>
            <v-row class="my-3 pa-3 warn-row text-center">
              <i
                class="fa fa-exclamation-triangle fa-2x text-warning ms-3 me-3"
                aria-hidden="true"
              ></i>
              <p class="mb-0 text-danger" style="line-height: 22px">
                تذکر: در این بخش شما شیفت جدیدی ثبت نمی کنید،فقط اطلاعات شیفت را
                تغییر می دهید.
              </p>
            </v-row>
            <div v-if="role == 'reception'">
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-progress-circular
                    v-if="busy || gettingPeople"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="thisPageShiftDetails.docId"
                    :items="drNames.filter((x) => x.expertise != 'دندانپزشک')"
                    item-text="text"
                    item-value="value"
                    label="نام پزشک*"
                    class="pa-0"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-progress-circular
                    v-if="busy || gettingPeople"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="thisPageShiftDetails.nurseId"
                    :items="nurseNames"
                    item-text="text"
                    item-value="value"
                    label="نام پرستار*"
                    class="pa-0"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <!--<v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-progress-circular
                    v-if="busy || gettingPeople"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="thisPageShiftDetails.dentistId"
                    :items="drNames"
                    item-text="text"
                    item-value="value"
                    label="دندانپزشک"
                    class="pa-0"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-progress-circular
                    v-if="busy || gettingPeople"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="thisPageShiftDetails.nurseDentistId"
                    :items="nurseNames"
                    item-text="text"
                    item-value="value"
                    label="دستیار دندانپزشک"
                    class="pa-0"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>-->
            </div>
            <div v-else-if="role == 'dentistryNurse'">
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-progress-circular
                    v-if="busy || gettingPeople"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="thisPageShiftDetails.docId"
                    :items="drNames.filter((x) => x.expertise == 'دندانپزشک')"
                    item-text="text"
                    item-value="value"
                    label="دندانپزشک"
                    class="pa-0"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-progress-circular
                    v-if="busy || gettingPeople"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="thisPageShiftDetails.nurseId"
                    :items="dentistNurseNames"
                    item-text="text"
                    item-value="value"
                    label="دستیار دندانپزشک"
                    class="pa-0"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <v-row v-if="role == 'reception'">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-progress-circular
                  v-if="busy || gettingPeople"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="thisPageShiftDetails.oncallDocId"
                  :items="
                    drNames.filter((x) => x.value != thisPageShiftDetails.docId)
                  "
                  item-text="text"
                  item-value="value"
                  label="نام پزشک آنکال"
                  class="pa-0"
                  clearable
                  outlined
                  dense
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-progress-circular
                  v-if="busy || gettingPeople"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="thisPageShiftDetails.oncallNurseId"
                  :items="
                    nurseNames.filter(
                      (x) => x.value != thisPageShiftDetails.nurseId
                    )
                  "
                  item-text="text"
                  item-value="value"
                  label="نام پرستار آنکال"
                  class="pa-0"
                  clearable
                  outlined
                  dense
                  disabled
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-select
                  v-model="thisPageShiftDetails.subject"
                  :items="shifts"
                  item-text="text"
                  item-value="value"
                  label="شیفت*"
                  disabled
                  outlined
                  dense
                ></v-select
              ></v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="role == 'reception'"
              >
                <v-progress-circular
                  v-if="busy || gettingPeople"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="thisPageShiftDetails.servantId"
                  :items="servantNames"
                  item-text="text"
                  item-value="value"
                  label="پرسنل خدمات"
                  clearable
                  outlined
                  dense
                ></v-autocomplete> </v-col
            ></v-row>
            <v-row class="mt-0">
              <v-col>
                <v-text-field
                  label="نام پذیرش"
                  outlined
                  v-model="userName"
                  disabled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <v-btn
                  :disabled="isBusy"
                  style="width: 15%"
                  class="submit-btn primary-btn"
                  @click="changeDetails()"
                  >ثبت
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isShift: true,
      Busy: false,
      shiftId: "",
      shifts: [
        { value: "صبح", text: "صبح" },
        { value: "عصر", text: "عصر" },
        { value: "شب", text: "شب" },
      ],
      busy: false,
      isBusy: false,
      thisPageShiftDetails: {},
      gettingPeople: false,
      role: "",
    };
  },

  methods: {
    ...mapActions(["getCurrent"]),
    shiftInfo() {
      setTimeout(() => {
        this.thisPageShiftDetails = JSON.parse(
          JSON.stringify(this.shiftDetails)
        );
      }, 500);
    },
    changeDetails() {
      //we send the data that we've changed and at end we get the shift info again
      if (this.isShift) {
        this.isBusy = true;
        if(this.role == 'reception'){
          this.$http
          .post(
            this.baseUrl + "/clinic/shift/editShift",
            {
              docId: this.thisPageShiftDetails.docId,
              nurseId: this.thisPageShiftDetails.nurseId,
              shiftId: this.thisPageShiftDetails.id,
              subject: this.thisPageShiftDetails.subject,
              oncallDocId: this.thisPageShiftDetails.oncallDocId
                ? this.thisPageShiftDetails.oncallDocId
                : "",
              oncallNurseId: this.thisPageShiftDetails.oncallNurseId
                ? this.thisPageShiftDetails.oncallNurseId
                : "",
              servantId: this.thisPageShiftDetails.servantId
                ? this.thisPageShiftDetails.servantId
                : "",
              dentistId: this.thisPageShiftDetails.dentistId
                ? this.thisPageShiftDetails.dentistId
                : "",
              nurseDentistId: this.thisPageShiftDetails.nurseDentistId
                ? this.thisPageShiftDetails.nurseDentistId
                : "",
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.getCurrent();
              this.toast("اطلاعات شیفت به روزرسانی شد.", "success");
              this.isBusy = false;
              this.getCurrent();
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.isBusy = false;
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.isBusy = false;
          });
        } else if(this.role == 'dentistryNurse'){
          this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/shift/editShift",
            {
              docId: this.thisPageShiftDetails.docId,
              nurseId: this.thisPageShiftDetails.nurseId,
              shiftId: this.thisPageShiftDetails.id,
              subject: this.thisPageShiftDetails.subject,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.getCurrent();
              this.toast("اطلاعات شیفت به روزرسانی شد.", "success");
              this.isBusy = false;
              this.getCurrent();
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.isBusy = false;
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.isBusy = false;
          });
        }
        
      } else {
        this.toast("در حال حاضر شیفتی برای تغییر در جریان نیست", "error");
      }
    },
  },
  computed: {
    ...mapGetters([
      "drNames",
      "nurseNames",
      "servantNames",
      "shiftDetails",
      "successLoaded",
      "userName",
      "dentistNurseNames"
    ]),
  },
  mounted() {
    this.role = localStorage.getItem("role");

    this.shiftInfo();
  },
};
</script>

<style>
.warn {
  border: 1px solid red;
  border-radius: 5px;
  margin: 10px;
  margin-top: 0;
  padding: 5px;
}
</style>
